import React, { useEffect, useState } from 'react';
import styles from '../styles/Home.module.css';
import Head from 'next/head';
import { GoogleLogin } from '@react-oauth/google';
import { useSearchParams } from 'next/navigation';

const Home = () => {
  const [videoIndex, setVideoIndex] = useState(0);
  const searchParams = useSearchParams();
  const [showToast, setShowToast] = useState(false);

  const scrollToAnchor = (anchorName: string) => {
   
    if (anchorName) {
      let anchorElement = document.getElementById(anchorName);
      if (anchorElement) {
        anchorElement.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }
  };

  const Toast = () => {
    const [isVisible, setIsVisible] = useState(false);
    return (
      <div className={`${styles.toast} ${isVisible ? styles.visible : ''}`}>
        <img src="/images/success.png" alt="" />
        <p>Login successful, please open Harmone AI</p>
      </div>
    );
  };


  const logins = async (credentialResponse: any) => {
    try {
      const response = await fetch('https://harmone.ai/hapi/login/google', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: credentialResponse.credential }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const fetchedData = await response.json();
      console.log("🚀 ~ logins ~ fetchedData:", fetchedData)
      setShowToast(true);
      setTimeout(()=>{
        setShowToast(false);
      },3000)
      window.postMessage({ type: 'OPEN_HARMONE_LOGIN', message: JSON.stringify(fetchedData)}, '*');
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

//  
  return (
    <>
      <Head>
        <title>Harmone.ai</title>
        <link rel="icon" href="/images/favicon.ico" />
        <meta name="description" content="Harmone.ai See What You See, Hear What You Hear" />
        <meta
          name="keywords"
          content="video companion ai, 
          Videos companion,
          videos companion ai, 
          ai video companion, 
          watch video with AI,
          watch video with ai in realtime,
          AI watching video in real-time,
          watch video with ai,
          ai video companion,
          harmone.ai,
          Harmone.ai,
          harmone.AI,
          Harmone.AI"
        />
      </Head>
      <main className="main">
        <div className="content">
          <div className={styles.header}>
            {/* <div className={styles.header_img}>
              <img src="/images/paper-airplanes.jpg" alt="" />
            </div> */}
            <video
              id="video"
              aria-hidden="true"
              x-webkit-airplay="true"
              webkit-playsinline="true"
              playsInline={true}
              x5-playsinline="true"
              x5-video-player-type="h5"
              loop
              muted
              autoPlay
              poster="/images/paper-planes.jpg"
              className={styles.header_video}
            >
              <source src="/images/paper-planes.mp4" type="video/mp4" />
            </video>
            <div className={styles.header_a}>
              <div className={styles.header_b}>
                {showToast ? <Toast/> : null}
                <div className={styles.name}>Harmone.ai</div>
                {searchParams.get('type') ? (
                  <GoogleLogin
                    locale='en'
                    onSuccess={(credentialResponse) => {
                      logins(credentialResponse);
                    }}
                    onError={() => {
                      console.log('Login Failed');
                    }}
                    useOneTap
                  />
                ) : (
                  <div className={styles.tabContainer}>
                    <div className={styles.tab} onClick={() => scrollToAnchor('About')}>
                      <a>About</a>
                    </div>
                    <div className={styles.tab} onClick={() => scrollToAnchor('Media')}>
                      <a>Media</a>
                    </div>
                  </div>
                )}
              </div>

              <div className={styles.see_b}>
                {/* <div className={styles.intro}>Introducing  HARMONE.AI</div> */}
                <div className={styles.see_t}>See What You See,</div>
                <div className={styles.see_tb}>Hear What You Hear.</div>
                <div className={styles.enjoy_t}>
                  Enjoy any virtual, entertainment, or real-world setting with characters who, in
                  real-time, empathize with you, join in your experiences, starting with watching
                  videos together.
                </div>
                {/* <div className={styles.enjoy_tb}>
                  
                </div> */}
                {/* <div className={styles.enter}>Enter Your Email to the Waitlist</div>
              <input className={styles.see_i} type="text" name="" id="" placeholder="Enter Email" /> */}
                <a
                  className={styles.btn}
                  href="https://docs.google.com/forms/d/1o5tESsbFIV-pft_Ch414VwuVy-qoC3QL6LbJJgYmDwM/edit#settings"
                >
                  Join Waitlist!
                </a>
              </div>
            </div>
          </div>

          <div className={styles.list} id="About">
            <div className={styles.list_b}>
              <div className={styles.list_item}>
                <img className={styles.list_img} src={'/images/cover2.jpg'} alt="" />
                <div className={styles.list_r}>
                  <div className={styles.list_num}>1</div>
                  <div className={styles.list_title}>Choose any characters to watch with!</div>
                  <div className={styles.list_desc}>
                    <p>
                      Choose LeBron James to enjoy an NBA game together, choose Deadpool to binge
                      watch Keeping Up With The Kardashians… There&apos;s nothing you can&apos;t
                      achieve, it&apos;s only what you haven&apos;t thought of yet!{' '}
                    </p>
                  </div>
                </div>
              </div>

              <div className={styles.list_item}>
                <div className={styles.list_r}>
                  <div className={styles.list_num}>2</div>
                  <div className={styles.list_title}>Generate any characters as you like!</div>
                  <div className={styles.list_desc}>
                    <p>
                      Who&apos;s your top Vtuber pick? Got a fave novel character? How about your
                      secret crush? (Yep, it might sound cringe). Drop in a quick description,
                      upload a voice snippet, and boom! Binge watch with any characters you like!
                    </p>
                  </div>
                </div>
                <img className={styles.list_img} src="/images/cover.jpg" alt="" />
              </div>

              <div className={styles.list_item}>
                <img className={styles.list_img1} src="/images/cover3.jpg" alt="" />
                <div className={styles.list_r}>
                  <div className={styles.list_num}>3</div>
                  <div className={styles.list_title}>Few clicks to start watching anything!</div>
                  <div className={styles.list_desc}>Log in, choose your character, and watch!</div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.video} id="Media">
            <div className={styles.video_b}>
              <div className={styles.video_t}>
                <div
                  onClick={() => setVideoIndex(0)}
                  className={videoIndex === 0 ? styles.video_check : ''}
                >
                  Sports
                </div>
                <div
                  onClick={() => setVideoIndex(1)}
                  className={videoIndex === 1 ? styles.video_check : ''}
                >
                  Anime
                </div>
                <div className={videoIndex === 2 ? styles.video_check : ''}>
                  Endless experience await your discovery...
                </div>
              </div>
              <div className={styles.video_desc}>
                {videoIndex === 0 ? (
                  <p>
                    Watch an NBA game with LeBron, or enjoy sports games with other renowned
                    athletes, professional sports commentators, or anyone you like, discussing the
                    intricacies of the game for an unparalleled viewing experience!
                  </p>
                ) : (
                  ''
                )}
                {videoIndex === 1 ? (
                  <p>
                    Watch the first episode of AOT with Eren from the final season! What are his
                    thoughts now about everything, and would he change any of his decisions? Join
                    anime characters, creators, or actors to watch all kinds of shows and movies,
                    discuss plots, and binge-watch non-stop with your favorite characters!
                  </p>
                ) : (
                  ''
                )}
                {videoIndex === 2 ? <p>Endless experiences await your discovery!</p> : ''}
              </div>
              <div>
                {videoIndex === 0 ? (
                  <video className={styles.video_item} aria-hidden="true" loop autoPlay controls>
                    <source src="/images/anime.mp4" type="video/mp4" />
                  </video>
                ) : (
                  ''
                )}
                {videoIndex === 1 ? (
                  <video className={styles.video_item} aria-hidden="true" loop autoPlay controls>
                    <source src="/images/sports.mp4" type="video/mp4" />
                  </video>
                ) : (
                  ''
                )}
                {videoIndex === 2 ? (
                  <div className={styles.video_more}>Endless experiences await your discovery!</div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>

          <div className={styles.say}>
            <div className={styles.say_b}>
              <div className={styles.say_title}>why Harmone.Ai</div>
              <div className={styles.say_desc}>What People Have Saying...</div>
              <div className={styles.say_list}>
                <div className={styles.say_item}>
                  <div className={styles.say_item_t}>
                    <img src="/images/head1.jpg" alt="" />
                    <div className={styles.say_item_name}>Catherine. S</div>
                  </div>
                  <div className={styles.say_item_desc}>
                    I&apos;m head over heels for Harmone! I just finished watching an episode of
                    Naruto with Gojo Satoru by my side, and I&apos;m still buzzing from the
                    experience. Watching his reaction to Kakashi, who has a similar style,
                    Gojo&apos;s literally said: &ldquo;This is a rip-off version of me, and
                    he&apos;s using my blindfold as a mouth cover—that&apos;s a 0/10 cosplay,&ldquo;
                    was hilarious🤣. Every episode is so much more enjoyable, and I strongly
                    recommend trying it out to see characters from different shows watching each
                    other&apos;s shows. You&apos;re in for some unexpected fun!
                  </div>
                </div>
                <div className={styles.say_item}>
                  <div className={styles.say_item_t}>
                    <img src="/images/head2.jpg" alt="" />
                    <div className={styles.say_item_name}>Luke. Y</div>
                  </div>
                  <div className={styles.say_item_desc}>
                    Harmone has transformed my sports viewing into an epic event. Watching the
                    latest NFL games with a virtual Tom Brady commenting on plays and strategies was
                    surreal. His AI persona&apos;s insights and reactions were incredibly lifelike,
                    adding depth and excitement to every touchdown and interception. It&apos;s like
                    having a Super Bowl MVP right there in your living room!
                  </div>
                </div>
                <div className={styles.say_item}>
                  <div className={styles.say_item_t}>
                    <img src="/images/head3.jpg" alt="" />
                    <div className={styles.say_item_name}>Jessy. L</div>
                  </div>
                  <div className={styles.say_item_desc}>
                    Harmone has turned my YouTube viewing into an interactive adventure. I recently
                    spent an evening watching a series of cooking challenges with Gordon Ramsay AI.
                    Besides feeling like a personal cooking masterclass, Gordon&apos;s roasts on
                    every bad take the chef makes had me dying 🤣. It&apos;s absolutely a fantastic
                    experience, and I can&apos;t wait to try new characters!!
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.footer}>
            <div className={styles.name}>Harmone.ai</div>
            <div className={styles.tabContainer}>
              <a className={styles.tab} href="https://discord.com/invite/5SZ7gZVGAY">
                <img width={24} height={24} src="/images/icon.jpg" alt="" />
              </a>
              <a className={styles.tab} href="https://twitter.com/harmone2023 ">
                <img width={24} height={24} src="/images/icon1.jpg" alt="" />
              </a>
              <a className={styles.tab} href="https://www.instagram.com/harmonein2023?">
                <img width={24} height={24} src="/images/icon2.jpg" alt="" />
              </a>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Home;
